import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import * as FullStory from "@fullstory/browser";

import AppHeader from "components/AppHeader";
import PrivateRoutes from "components/PrivateRoutes";

import Home from "pages/home";
import ClientHomePage from "pages/client-home/client-home";
import Approve from "pages/approve/Approve";
import IntelligenceCenter from "pages/intelligence-center/intelligence-center";

import { InstitutionStore } from "context/InstitutionContext";

import { ClientStore } from "context/ClientContext";
import { FileMappingsStore } from "context/FileMappingsContext";
import { FileMaskStore } from "context/FileMasksContext";
import { ListPlusStore } from "context/ListPlusContext";
import { AuthContext } from "context/AuthContext";
import { ConfigStore } from "context/ConfigContext";
import { FilesplitStore } from "context/FilesplitContext";

import appRoles from "config/appRoles";
import { FeedbackStore } from "context/FeedbackContext";

const AuthenticatedApp = () => {
  const { isAuthorized, user } = useContext(AuthContext);

  FullStory.identify(user?.email);

  return (
    <FeedbackStore>
      <InstitutionStore>
        <ClientStore>
          <FilesplitStore>
            <ConfigStore>
              <FileMaskStore>
                <FileMappingsStore>
                  <ListPlusStore>
                    <BrowserRouter>
                      <AppHeader />
                      <Routes>
                        <Route
                          path="/"
                          element={isAuthorized(appRoles.IsIntelligenceCenterOnly) ? <Navigate to="/intelligence/summaries" /> : <Home />}
                        />
                        <Route path="/intelligence/*" element={<IntelligenceCenter />} />
                        <Route exact path="clients/:clientid/*" element={<ClientHomePage />} />
                        {!isAuthorized(appRoles.IsIntelligenceCenterOnly) && (
                          <Route element={<PrivateRoutes allowedAppRoles={appRoles.IsConfigApprover} />}>
                            <Route exact path="/approve/*" element={<Approve />} />
                          </Route>
                        )}
                        {!isAuthorized(appRoles.IsIntelligenceCenterOnly) && (
                          <Route element={<PrivateRoutes allowedAppRoles={appRoles.IsAiTranscriptSummarizationReader} />}>
                            <Route exact path="/intelligence/*" element={<IntelligenceCenter />} />
                          </Route>
                        )}
                      </Routes>
                    </BrowserRouter>
                  </ListPlusStore>
                </FileMappingsStore>
              </FileMaskStore>
            </ConfigStore>
          </FilesplitStore>
        </ClientStore>
      </InstitutionStore>
    </FeedbackStore>
  );
};

export default AuthenticatedApp;
