import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, getCoreRowModel, getPaginationRowModel, getSortedRowModel } from "@tanstack/react-table";
import classNames from "classnames";
import CustomTextInput from "../custom-text-input/custom-text-input";

import PaginationWrapper from "components/partials/table-component/pagination-wrapper/pagination-wrapper";
import { Table } from "components/partials/table-component/table/table";

const TableComponent = ({
  columns,
  data,
  isLoading,
  pageIndex,
  pageSize,
  setPagination,
  setAmount,
  sortingParams,
  manualSorting = false,
  setSortingParams,
  isMultiSort = false,
  totalRecords,
  showingRecordsTextIsOn = true,
  viewByAmountSelectIsOn = true,
  pagination = true,
  paginationOnTheBottom = false,
  lastRowBold = false,
  customHeight = "max-h-[62vh]",
  enableSearch = false,
  searchLabel = "",
  searchPlacholder = "",
  onSearchChange,
}) => {
  //set total amount of pages
  const pagesAmount = useMemo(() => Math.ceil(totalRecords / pageSize), [pageSize, totalRecords]);

  const onPaginationChange = (updater) => {
    const { pageIndex: newPageIndex } = updater({ pageIndex, pageSize });

    setPagination(newPageIndex);
  };

  //set table props
  const table = useReactTable({
    data,
    columns,
    manualSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: pagesAmount,
    state: { sorting: sortingParams, pageIndex, pageSize },
    onPaginationChange: onPaginationChange,
    enableSortingRemoval: false,
    onSortingChange: setSortingParams,
    enableMultiSort: isMultiSort,
    isMultiSortEvent: () => true,
    maxMultiSortColCount: 2,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (enableSearch && !isLoading) {
      const handler = setTimeout(() => {
        onSearchChange(searchTerm);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchTerm]);

  return (
    <div className="flex h-full shrink grow basis-0 flex-col">
      {!!pagination && (
        <div className={classNames(`mb-2 flex items-end ${enableSearch ? "justify-between" : "justify-end"}`)}>
          {!!enableSearch && (
            <CustomTextInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={searchPlacholder}
              inputCustomClass="w-64 px-2 py-1 border rounded-md"
              label={searchLabel}
              labelInTop={true}
            />
          )}
          <PaginationWrapper
            showingRecordsTextIsOn={showingRecordsTextIsOn}
            viewByAmountSelectIsOn={viewByAmountSelectIsOn}
            table={table}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRecords={totalRecords}
            pagesAmount={pagesAmount}
            setAmount={setAmount}
          />
        </div>
      )}
      <Table
        {...{
          table,
          sortingParams,
          isLoading,
          isMultiSort,
          customHeight,
          lastRowBold,
        }}
      />
      {!!pagination && !!paginationOnTheBottom && (
        <div className={classNames("mb-2 flex items-center justify-end")}>
          <PaginationWrapper
            showingRecordsTextIsOn={showingRecordsTextIsOn}
            viewByAmountSelectIsOn={viewByAmountSelectIsOn}
            table={table}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRecords={totalRecords}
            pagesAmount={pagesAmount}
            setAmount={setAmount}
          />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
