import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TableComponent from "components/partials/table-component/table-component";
import { tableSettings } from "./meetings-tablesettings";
import Heading from "components/partials/heading/heading";
import CustomButton from "components/partials/custom-button/custom-button";
import Icons from "assets/icons";
import RadioModal from "./new-summary/new-summary-radio-modal";
import { MeetingContext } from "context/MeetingContext";
import { ToastContainer } from "react-toastify";
import { MeetingDetailContext } from "context/MeetingDetailContext";

const Meetings = () => {
  const {
    fetchMeetings,
    recentMeetings,
    pageSize,
    totalRecords,
    setAmount,
    pageIndex,
    setPageIndex,
    sortingParams,
    setSortingParams,
    isMeetingsLoading,
  } = useContext(MeetingContext);

  const { resetMeetingDetails } = useContext(MeetingDetailContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sourceOptions = [
    { label: "Gong", value: "gong" },
    { label: "File upload (VTT)", value: "manual" },
  ];
  const navigate = useNavigate();

  const handleCreateSummary = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSelect = (selectedValue) => {
    navigate(`new/${selectedValue}`);
    setIsModalOpen(false);
  };

  useEffect(() => {
    resetMeetingDetails();
  }, []);

  const handleSearch = (term) => {
    if (!isMeetingsLoading) {
      fetchMeetings(false, term);
    }
  };

  return (
    <>
      <ToastContainer style={{ width: "100%", fontSize: "16px" }} progressStyle={{ backgroundColor: "#b6de3a" }} />
      <div className="mb-9 flex shrink grow basis-0 flex-col gap-2">
        <div className="mb-5 flex items-center justify-between">
          <Heading variant="h1" customClass="font-bold">
            Recent Meetings
          </Heading>
          <CustomButton
            variant="primary"
            size="md"
            iconBefore={<Icons.Intelligence aria-label="Upload batch" height="100%" width="25" />}
            onClick={handleCreateSummary}
          >
            New Meeting Summary
          </CustomButton>
        </div>
        <TableComponent
          columns={tableSettings()}
          data={recentMeetings}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={(page) => {
            setPageIndex(page);
          }}
          setAmount={setAmount}
          sortingParams={sortingParams}
          setSortingParams={setSortingParams}
          totalRecords={totalRecords}
          customHeight={recentMeetings.length === pageSize && "shrink grow basis-0"}
          enableSearch={true}
          onSearchChange={handleSearch}
          searchLabel="Search for Meeting or Partner Name"
          searchPlacholder="Search for meeting or partner..."
          isLoading={isMeetingsLoading}
        />
        {isModalOpen && <RadioModal onClose={handleCloseModal} onSelect={handleSelect} sourceOptions={sourceOptions} />}
      </div>
    </>
  );
};

export default Meetings;
